.satty-home-title{
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1.0;
    margin-bottom: 5px;
    color: #8b03cb;
}

.satty-image-background-container{
    width: 100%;
    height: 100%;
}

.satty-image-background-title{
    color: #565656;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1.0;
    padding: 5px;
    text-align: right;
    padding-right: 3rem;
    padding-left: 3rem;
}

.satty-image-background-text{
    color: #565656;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 1.25rem;
    line-height: 1.0;
    padding: 5px;
    text-align: right;
    padding-right: 3rem;
    padding-left: 3rem;
}

.satty-features-title{
    font-size: 2.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #565656;
    line-height: 1.0;
    padding: 0.1rem 0;
    text-align: center;
}

.satty-features-subtitle{
    font-size: 1.5rem;
    font-weight: 200;
    font-family: "Montserrat", sans-serif;
    color: #565656;
    line-height: 1.0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
}

.satty-features-label{
    font-size: 1.25rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #565656;
    line-height: 1.0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
}


.satty-screen-subtitle{
    font-size: 1.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #565656;
    line-height: 1.0;
    padding-bottom: 0.5rem;
}

.satty-screen-title{
    font-size: 2.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #8b03cb !important;
    line-height: 1.0;
    padding: 0.1rem 0;
}

.spiderProduct-section--main p {
    font-size: 1.25rem;
}

@media (max-width: 575px) {

    .satty-home-title{
        text-align: center;
    }

    .satty-home-img{
        display: flex;
        justify-content: center;
    }

    .spiderProduct-section--main{
        text-align: center;
    }

    .satty-image-background-title{
        width: auto;
    }

    .satty-features-title{
        text-align: center;
    }
}