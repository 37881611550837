#Home .spiderProduct-section--img img {
  width: 70vh !important;
}

#Home .spiderProduct-section--action {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ee2259;
  cursor: pointer;
  padding: 10px;
}

#Home .spiderProduct-section--img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#Home .spiderProduct-section--action a {
  font-weight: 800;
  color: #f5f5f5;
}

#Home .spiderProduct-section--header {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  #Home {
    flex-direction: row;
  }
}

#monitor h1 {
  color:#87189dff;
}

#alert h1 {
  color:#87189dff;
}