.feeder-manager-home-title{
    font-size: 2.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #3d245f !important;
    line-height: 1.0;
    padding: 0.1rem 0;
}

.feeder-manager-home-subtitle{
    font-size: 1.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #565656;
}