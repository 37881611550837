.spiderProduct-Screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 0rem;
  background-color: #f8faff;
  font-size: 16px;
  margin-top: 60px;
}

header {
  position: sticky;
  width: 100vw;
}

#routes {
  width: 100vw;
  overflow-x: hidden;
}

.spiderProduct-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 2rem 0 !important;
  padding: 0 3rem !important;
}

.spiderProduct-section--container-action {
  display: flex;
  align-items: center;
}

.spiderProduct-section--header h1 {
  color: #ee2259;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
}

.spiderProduct-section--header h3 {
  font-size: 1.5rem;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  color: #565656;
  line-height: 1.0;
  padding-bottom: 0.5rem;
}

.spiderProduct-section--header h2 {
  font-size: 2.5rem;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  color: #565656;
  text-align: center;
  line-height: 1.0;
  padding: 0.1rem 0;
}

.spiderProduct-section--footer {
  width: 100%;
  margin: 0px;
}

.spiderProduct-section--footer h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  padding-bottom: 15px;
  font-size: 20px;
}

.spiderProduct-section--footer ul {
  font-family: "Poppins", sans-serif;
  color: #565656;
  margin-bottom: 25px;
  font-size: 16px;
}

.spiderProduct-section--footer ul li {
  list-style-type: none;
  padding: 0px 15px;
}

.spiderProduct-section--main {
  padding: 0 1rem;
}

.satty-section--footer {
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #565656;
  text-align: center;
  font-size: 20px;
  line-height: 35px;
}

.spiderProduct-section--main p {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #565656;
  text-align: center;
  font-size: 16px;
  margin: 0px;
}

.spiderProduct-section--data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.spiderProduct-section--img {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.app-screenshots{
  padding: 0px 25px;
}

.section-title-border {
  width: 150px;
  height: 3px;
  margin-right: auto;
  margin-left: auto;
  background-color: #8b03cbff;
  margin-top: 1rem;
}

.xFarm-title-border {
  width: 150px;
  height: 3px;
  margin-right: auto;
  margin-left: auto;
  background-color: #8b03cbff;
  margin-top: 1rem;
}


/*
  width: 100vw;

.spiderProduct-section--img img {
  height: auto;
  width: 100%;
}*/

#acoustic-feeder .spiderProduct-section--img img {
  width: 70vh !important;
}

#acoustic-ml .spiderProduct-section--img img {
  width: 70vh !important;
}

#acoustic-ia .spiderProduct-section--img img {
  width: 70vh !important;
}

#MonitoreoAgua .spiderProduct-section--img img {
  width: 70vh !important;
}

#Feeder .spiderProduct-section--img img {
  width: 70vh !important;
}

#FeedCrop .spiderProduct-section--img img {
  width: 60vh !important;
}

#Tech .spiderProduct-section--img img {
  width: 60vh !important;
}

.reverse {
  flex-direction: row-reverse !important;
}

#MonitreoPiscinas img,
#FeedCrop img {
  width: 60vw;
}

#SensoresCompatibles img {
  width: 65vw;
}

#Center img,
#Tech img,
#Precision img {
  width: 80vw;
}

.components-title-items{
  margin: 0px;
  padding: 0px 25px;
}

#Finca-tech{
  padding: 75px 0px;
}

@media screen and (min-width: 768px) {
  #SensoresCompatibles img {
    width: 35vw;
  }
  #screenshots img {
    width: 20vw !important;
  }
}

@media screen and (min-width: 1024px) {
  #SensoresCompatibles img {
    width: 25vw;
  }

  #MonitreoPiscinas img,
  #FeedCrop img {
    width: 30vw;
  }
  #screenshots img {
    width: 20vw !important;
  }
}

@media screen and (min-width: 1440px) {
  #screenshots img {
    width: 25vw !important;
  }
}

@media screen and (min-width: 1920px) {
  .spiderProduct-section--header h1 {
    color: #ee2259;
    font-weight: 900;
    font-family: "Montserrat", sans-serif;
    font-size: 70px;
  }

  .spiderProduct-section--header h2 {
    font-size: 60px;
  }

  .spiderProduct-section--footer {
    width: 100%;
    margin: 0px;
  }

  .spiderProduct-section--footer h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    padding-bottom: 15px;
    font-size: 40px;
  }

  .spiderProduct-section--footer ul {
    font-family: "Poppins", sans-serif;
    color: #565656;
    margin-bottom: 25px;
    font-size: 22px;
  }

  .spiderProduct-section--footer ul li {
    list-style-type: none;
    padding: 0px 15px;
    margin: 10px 0;
  }

  .spiderProduct-section--container-action a {
    font-size: 28px;
    padding: 0 1rem;
  }

  #Components img {
    width: 100vw;
  }

  #FeedCrop img {
    width: 20vw;
  }

  /* 
  
  preguntar 
  
  .spiderProduct-section .spiderProduct-section--img img {
    width: 25vw;
  } */

  .spiderProduct-section--main p {
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    color: #565656;
    text-align: center;
    font-size: 22px;
    margin: 0px;
  }

  .spiderProduct-section--main strong {
    font-size: 22px;
  }

  #Center img,
  #MonitreoPiscinas img {
    width: 25vw;
  }

  #SensoresCompatibles img {
    width: 15vw;
  }
}
