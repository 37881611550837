
.xAgro-home-container{
    width: 100%;
    height: 100%;
    padding-top: 2rem;
    padding-bottom: 4rem;
}

.xAgro-home-title{
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1.3;
    padding: 0px 5px;
    margin-bottom: 5px;
}

.xAgro-home-mark{
    color: white !important;
    background: linear-gradient(-47deg, #2a3890 0%, #2a3890 100%);
}

.xAgro-home-subtitle{
    color: white !important;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 1.5;
    padding-top: 0px;
    padding-left: 18px;
    padding: 0px 5px;
    margin-bottom: 5px;
}

#xAgro-features-platform{
    background-color: #f8faff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 2rem 0 !important;
    padding: 0 3rem !important;
}

.xAgro-features-platform-container{
    padding: 1rem 1rem;
}

.xAgro-features-platform-title{
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    padding: 1rem 1rem;
    color: #565656;
}

.xAgro-features-platform-text{
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 1.25rem;
    padding: 1rem 1rem;
    color: #565656;
    line-height: 1.5;
}

.xAgro-features-platform-container h4{
    line-height: 1.2;
    color: #565656;
    font-weight: 700;
    font-size: 1.5rem;
    font-family: "Montserrat";
}

.xAgro-features-platform-container span{
    line-height: 1.2;
    color: #565656;
    font-size: 1.25rem;
    font-family: "Montserrat";
}

.xAgro-resume-section{
    width: 100%;
    padding: 5rem 3rem;
}

.xAgro-resume-title{
    color: white !important;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
}

.xAgro-resume-subtitle{
    color: white !important;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 1.5rem;
    padding: 2rem 0rem;
}

.xAgro-resume-container{
    padding: 1rem;
}

.xAgro-resume-container h3{
    font-size: 1.5rem;
}

.xAgro-resume-container p{
    font-size: 1.25rem;
}

.xAgro-network-container{
    width: 100%;
    height: 100%;
    padding-bottom: 4rem;
}

.xAgro-network-title{
    color: #565656;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1.0;
    padding: 1rem 1rem;
    margin: 1rem 0rem;
    text-align: right;
}

.xAgro-network-text{
    color: #565656 !important;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: 1rem 1rem;
    margin: 0.25rem 0rem;
    text-align: right;
}

.xAgro-network-content{
    background-color: #f8faff;
    padding-right: 3rem;
}

.section-title-border{
    background: #2a3890ff !important;
}

.spiderProduct-section--header h1 {
    font-size: 2.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #565656 !important;
    line-height: 1.0;
    padding: 0.1rem 0;
}

.xAgro-screen-section,.xAgro-digital-tools-section {
    width: 100%;
    height: 100%;
    margin: 2rem 0 !important;
    padding: 0 3rem !important;
}

.xAgro-screen-title{
    font-size: 2.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #2a3890ff !important;
    line-height: 1.0;
    padding: 0.1rem 0;
}

.xAgro-screen-subtitle{
    font-size: 1.5rem;
    font-weight: 800;
    font-family: "Montserrat", sans-serif;
    color: #565656 !important;
    line-height: 1.0;
    padding-bottom: 0.5rem;
}

.spiderProduct-section--main{
    font-size: 1.25rem;
    font-family: "Montserrat", sans-serif;
    color: #565656 !important;
    line-height: 1.0;
    padding-bottom: 0.5rem;
}

.spiderProduct-section--main p{
    font-size: 1.25rem;
}


.xAgro-digital-tools-subtitle{
    color: #565656 !important;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 1.5rem;
    padding: 0.25rem 3rem;
    text-align: right;
}

.xAgro-digital-tools-title{
    color: #565656 !important;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    padding: 0.25rem 0rem;
    text-align: right;
}

.xAgro-digital-tools-container{
    text-align: right !important;
}

.xAgro-digital-tools-container h4{
    text-align: right !important;
    color: #565656;
    font-weight: 700;
    font-size: 1.5rem;
}

.xAgro-digital-tools-container span{
    text-align: right !important;
    color: #565656;
    font-weight: 200;
    font-size: 1.25rem;
}

#xAgro-software{
    background: linear-gradient(-47deg, #2a3890 0%, #2a3890 100%);
    width: 100%;
}

.xAgro-software-title{
    color: white !important;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    text-align: left;
    padding: 1rem 3rem;
}

.xAgro-software-text{
    color: white !important;
    font-family: "Montserrat";
    font-weight: 200;
    font-size: 1.25rem;
    padding: 0.25rem 3rem;
    text-align: left;
}


.xAgro-footer-text{
    color: white !important;
    font-family: "Montserrat";
    font-weight: 800;
    font-size:2rem;
    padding: 0.25rem 0rem;
    background: linear-gradient(-47deg, #2a3890 0%, #2a3890 100%);
}

.xAgro-footer-btn{
    background: linear-gradient(-47deg, #2a3890 0%, #2a3890 100%) !important; 
    width: 200px;
}


@media (max-width: 575px) {
    
    .xAgro-home-title{
        text-align: center;
    }

    .xAgro-home-subtitle{
        color: #565656 !important;
        text-align: center;
        font-weight: 600;
    }

    .xAgro-network-content{
        padding: 0px;
    }

    .xAgro-network-title{
        text-align: center;
    }
    
    .xAgro-network-text{
        text-align: center;
    }

    .xAgro-software-title{
        text-align: center;
    }

    .xAgro-software-subtitle{
        text-align: center;
    }

    .xAgro-digital-tools-title{
        text-align: center;
    }

    .xAgro-digital-tools-container h4{
        text-align: left !important;
    }

    .xAgro-digital-tools-container span{
        text-align: left !important;
    }

  }
  
