#Features {
  padding: 75px 0px;
}

#Features .spiderProduct-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
  padding-top: 25px;
}

#Features .spiderProduct-section--header {
  text-align: center;
}

#Features .spiderProduct-section--list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.spiderProduct-section--list .spiderProduct-section--data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2rem;
  /*
  border-radius: 5px;
  box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.28);*/
  padding: 2rem;
}

#Features .spiderProduct-section--list .spiderProduct-section--img img {
  width: 25vw;
  height: auto;
}

#Features
  .spiderProduct-section--list
  .spiderProduct-section--data
  .spiderProduct-section--img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2rem;
}

@media screen and (min-width: 768px) {
  #Features .spiderProduct-section--list {
    flex-direction: row;
    align-items: flex-start;
    height: auto;
  }
  #Features .spiderProduct-section--list .spiderProduct-section--data {
    justify-content: flex-start;
  }
  #Features
    .spiderProduct-section--list
    .spiderProduct-section--data
    .spiderProduct-section--img {
    height: auto;
    width: 10vw;
  }
}

@media screen and (min-width: 1024px) {
  #Features .spiderProduct-section--list {
    flex-direction: row;
    align-items: flex-start;
    height: auto;
  }

  #Features .spiderProduct-section--list .spiderProduct-section--data {
    height: 100%;
  }

  #Features
    .spiderProduct-section--list
    .spiderProduct-section--data
    .spiderProduct-section--img {
    min-width: 15%;
    max-width: 30%;
    height: auto;
  }
}

@media screen and (min-width: 1920px) {
  #Features
    .spiderProduct-section--list
    .spiderProduct-section--data
    .spiderProduct-section--img
    img {
    width: 5vw;
  }
}
