/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* 
header {
  width: 100vw;
  z-index: 999;
  padding: 1rem;
  top: 0;
  left: 0;
  position: sticky;
} */

.main-container {
  overflow-x: hidden !important;
}

html {
  scroll-behavior: smooth;
}

#whatsappMe {
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  z-index: 999;
  width: 60px;
  height: 60px;
  background: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  transition: all 0.5s;
}

#whatsappMe:hover {
  transform: scale(1.1);
}

#whatsappMe a {
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #fff;
  justify-content: center;
  align-items: center;
}

#faq {
  padding: 2rem 0;
}

.branding-area,
#faq {
  background-color: #fff;
}