
.spider-feactures-section-title{
    color: #565656 !important;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1.5;
}

.spider-features-container{
    padding: 2px 3rem;
}

.spider-features-content{
    font-family: "Montserrat";
    line-height: 1.2 !important;
    font-size: 1.5rem;
    font-weight: 400;
    color: #565656;
    margin: 25px 0px;
    text-align: center;
    display: flex;
    align-items: center;
}


.spider-features-description-item-title{
    font-family: "Montserrat";
    font-weight: 800;
    color: #565656;
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 1.3;
    text-align: center;
    padding: 10px;
  }
  
.spider-features-description-item-content{
    font-family: "Montserrat";
    line-height: 1.2 !important;
    font-size: 1rem;
    font-weight: 200;
    color: #565656;
    margin-bottom: 0;
    text-align: center;
}
  
.spider-work-title-white{
    color: white !important;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1.5;
}

.spider-work-options-title-white{
    font-family: "Montserrat";
    font-weight: 800;
    color: white;
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
}

.spider-work-options-content-white{
    font-family: "Montserrat";
    line-height: 1.2 !important;
    font-size: 1.25rem;
    font-weight: 200;
    color: white;
    margin-bottom: 0;
    text-align: center;
}

.spider-work-options-content{
    font-family: "Poppins";
    line-height: 1.2 !important;
    font-size: 16px;
    font-weight: 400;
    color: #565656;
    margin-bottom: 0;
    text-align: center;
}

#acoustic-section{
    padding: 2rem 3rem;
}

.feature-acoustic-container{
    background: #f8faff;
    padding: 24px;
  }
  
.feature-acoustic-title{
    font-family: "Montserrat";
    font-weight: 800;
    color: #565656;
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
}

.feature-acoustic-img{
    padding: 20px;
    text-align: center;
}

.feature-acoustic-content{
    font-family: "Montserrat";
    line-height: 1.2 !important;
    font-size: 1.25rem;
    font-weight: 200;
    color: #231f20;
    margin-bottom: 0;
    text-align: center;
}

#SpiderWork{
    background: linear-gradient(-47deg, #df2a2d 0%, #bd292b 100%);
 }
  

#SpiderAcousticGains{
    background: linear-gradient(-47deg, #df2a2d 0%, #bd292b 100%);
}

.spider-acoustic-gains-section-title-white{
    color: white !important;
    font-family: "Montserrat";
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1.5;
}

.spider-acoustic-gains-options-title-white{
    font-family: "Montserrat";
    font-weight: 800;
    color: white;
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.5;
    text-align: center;
}

.spider-acoustic-gains-options-content-white{
    font-family: "Montserrat";
    line-height: 1.2 !important;
    font-size: 1.25rem;
    font-weight: 200;
    color: white;
    margin-bottom: 0;
    text-align: center;
}